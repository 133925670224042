import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Hero from "../../components/hero";
import ContactPerson from "../../components/contactPerson";
import { Facts } from "../../components/facts";
import { Count } from "../../components/count";
import { ServicesGrid } from "../../components/servicesGrid";
import { FloatTeaser } from "../../components/floatTeaser";
import { Container } from "../../components/container";

const HaiiloPartner = () => {
  const images = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "hero-haiilo.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      rene: file(relativePath: { eq: "profiles/rene-zoller.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      haiilo: file(relativePath: { eq: "partner/haiilo.png" }) {
        ...pngTracedLogo
      }
    }

    fragment pngTracedLogo on File {
      childImageSharp {
        gatsbyImageData(width: 380, placeholder: NONE, layout: CONSTRAINED)
      }
    }
  `);

  return (
    <Layout lang="de" langPath="partner.haiilo.link">
      <Seo
        title="Haiilo-Implementierungspartner – Monday Consulting"
        description="Als Haiilo Partner entwickeln wir eine digitale Heimat für Ihre Mitarbeiter. Mit der Employee Engagement Cloud erreichen Sie immer die richtige Zielgruppe."
        keywords="Haiilo, Intranet, Employee Engagement Cloud, Stories, Coyo, Home, Mitarbeiterkommunikation"
      />
      <div className="parallax-translate bg-shape-dots"></div>
      <Hero
        headline="Die Employee Engagement Cloud"
        subline="Mit Haiilo setzen wir nicht nur eine Intranet-Plattform für Sie um, sondern ein digitales Zuhause für Ihre Mitarbeiter."
        section="Haiilo Partner"
        styleClass="mb-lg-5"
        bgColor="green"
      >
        <GatsbyImage
          image={images.hero.childImageSharp.gatsbyImageData}
          className="hero-image"
          alt="Haiilo"
        />
      </Hero>

      <FloatTeaser offset={60}>
        <div className="container">
          <h3 className="text-size-4">
            Coyo, Smarp und Jubiwee werden zu Haiilo
          </h3>
          <p>
            Aus drei Vorreitern mit einer Mission wird eine neue gemeinsame
            Marke. Die drei Unternehmen aus Deutschland, Finnland und Frankreich
            haben sich zu einem globalen Anbieter für Social Intranet Software,
            Mitarbeiterkommunikation und Employee Engagement zusammengetan.
          </p>
        </div>
      </FloatTeaser>

      <div className="container mb-4 mb-md-5 mt-md-5 my-5">
        <div className="row flex-row-reverse">
          <div className="col-12 col-md-8 mb-4">
            <h2>Die digitale Heimat für alle Mitarbeiter</h2>
            <p>
              Wer glückliche Kunden will, braucht ein glückliches Team. Mit
              diesem Leitsatz unterstützt Monday Consulting seit vielen Jahren
              große und mittelständische Unternehmen bei der internen
              Kommunikation. Unsere Intranet-Lösungen bieten Ihnen die
              Möglichkeit, Informationen gezielt weiterzugeben. Sie stärken die
              Mitarbeiterbindung und bauen den Zusammenhalt im Unternehmen aus –
              auch über viele Standorte hinweg. Unsere Spezialisten setzten vor
              allem komplexe Strukturen und vielseitige Drittanbindungen gekonnt
              um, die Ihre internen Prozesse vereinfachen.
            </p>
            <p>
              Als Partner von Haiilo bieten wir Ihnen mit der Employee
              Engagement Cloud alle funktionalen Grundlagen, um die
              Unternehmenskultur zu stärken und eine umfassende Kommunikation am
              Arbeitsplatz zu etablieren. Die smarte Plattform besteht aus vier
              Produkten (Stories, Share, Home und Insights) und basiert auf
              bewährten, uns sehr gut bekannten Technologien. Unsere Entwickler
              kennen also nicht nur die Produktpalette, sondern auch die Technik
              dahinter. Auf komplexe Anforderungen, Integrationen und
              Anbindungen sind wir bestens vorbereitet. Mit unserer
              Content-Management-Expertise sorgen wir zudem dafür, dass Sie die
              Plattform optimal nutzen und das Beste aus ihr herausholen.
            </p>
            <p>
              Haiilo zählt zu den größten Anbietern für Social Intranets und
              Mitarbeiterkommunikation und ermöglicht es Unternehmen, ein
              digitales Zuhause für alle Kolleginnen und Kollegen zu schaffen
              und sie über viele Kanäle hinweg zu begeistern. Weltweit wird die
              Plattform von mehr als 2 Millionen Mitarbeitern genutzt und von
              mehr als 750 renommierten internationalen Kunden eingesetzt.
            </p>
          </div>
          <div className="col-12 col-md-4 px-4 d-flex flex-column align-items-center">
            <div className="w-75 mb-3">
              <GatsbyImage
                image={images.haiilo.childImageSharp.gatsbyImageData}
                className="w-100"
                alt="Haiilo"
              />
            </div>
          </div>
        </div>
      </div>

      <Container shape="square">
        <div className="container mt-6 mb-4 mb-md-5">
          <h2 className="col-12 text-center">
            Gute Gründe für Monday Consulting und Haiilo
          </h2>
          <Facts>
            <Count count="2009">
              Langjährige Erfahrung in der Entwicklung von Intranet-Auftritten.
            </Count>
            <Count count="100" suffix="%">
              Unsere Entwickler leben die verwendeten Technologien.
            </Count>
            <Count count="50000" prefix=">" separator=".">
              Mehr als 50.000 Mitarbeiter weltweit nutzen eine
              Intranet-Plattform, die von uns entwickelt wurde.
            </Count>
          </Facts>
        </div>
      </Container>

      <div className="container">
        <div className="row mb-5 mb-md-6 flex-md-row-reverse reveal">
          <div className="col-12 pb-4 mb-md-0 col-md-5 bg-shape-cross bg-position-tl bg-size-50">
            <span className="countable text-size-0 text-color-monday-skyblue">
              1
            </span>
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">Multichannel</h2>
            <h3>Haiilo Stories</h3>
            <p>
              Eine einfache Intranet-Plattform reicht heute nicht mehr aus, um
              alle Informationen an die richtigen Zielgruppen auszuspielen.
              Deswegen werden mit Haiilo Stories Mitarbeitende zusätzlich auf
              ihren bevorzugten Kanälen angesprochen: E-Mail, SharePoint, Slack,
              Digital Signage, Mitarbeiter-App und mehr. Die
              Multichannel-Kommunikation funktioniert in Echtzeit. Weniger
              Aufwand, mehr Engagement.
            </p>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 reveal">
          <div className="col-12 pb-4 mb-md-0 col-md-5 bg-shape-dots bg-position-tr bg-size-50">
            <span className="countable text-size-0 text-color-monday-skyblue text-right">
              2
            </span>
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">Vielzahl an Features</h2>
            <h3>Teambuilding in voller Bandbreite</h3>
            <p>
              Für die Steigerung des Employee Engagements hält Haiilo weitere
              Anbindungen out-of-the-box bereit. Haiilo Home ist das Social
              Intranet von morgen und bietet den Mitarbeitern eine digitale
              Heimat. Eine übersichtliche Plattform, die einfach um nützliche
              Integrationen wie Microsoft Teams erweitert werden kann. Haiilo
              Insights liefert Ihnen wertvolle Einblicke in die
              Mitarbeiterzufriedenheit. Und Haiilo Share gibt Ihren Mitarbeitern
              die Möglichkeit, selbst zu Markenbotschaftern zu werden.
            </p>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 flex-md-row-reverse reveal">
          <div className="col-12 pb-4 mb-md-0 col-md-5 bg-shape-circle bg-position-tl bg-size-50">
            <span className="countable text-size-0 text-color-monday-skyblue">
              3
            </span>
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">DSGVO</h2>
            <h3>Datenschutzkonforme Kommunikation</h3>
            <p>
              Haiilo bietet eine sichere und DSGVO-konforme Plattform, die
              dennoch übersichtlich angelegt und intuitiv bedienbar ist. Mit der
              modernen Intranet-Lösung kann eine datenschutzkonforme
              Kommunikation im gesamten Unternehmen garantiert werden und Sie
              können sicher sein, dass Dritte nicht auf interne Informationen
              zugreifen können.
            </p>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 reveal">
          <div className="col-12 pb-4 mb-md-0 col-md-5 bg-shape-square bg-position-tr bg-size-50">
            <span className="countable text-size-0 text-color-monday-skyblue text-right">
              4
            </span>
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">Komplexe Strukturen</h2>
            <h3>Intranet-Expertise</h3>
            <p>
              Mit Monday Consulting haben Sie einen Partner, der auf komplexe
              Strukturen und facettenreiche Anbindungen spezialisiert ist. Dank
              unserer langjährigen Erfahrung in der Entwicklung von
              Intranet-Plattformen entwickeln wir für jede Anforderung die
              passende Lösung.
            </p>
          </div>
        </div>
      </div>

      <ServicesGrid title="Wir bringen Sie nach vorne" />

      <div className="content-container mt-5 text-color-white text-center" />

      <ContactPerson
        headline="Legen Sie Ihr Haiilo-Projekt in unsere Hände."
        text="Für unsere Experten ist keine Herausforderung zu groß. Teilen Sie uns noch heute Ihre Wünsche und Anforderungen mit."
        contactEmail="haiilo@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="René Zoller, Haiilo-Experte"
      >
        <GatsbyImage
          image={images.rene.childImageSharp.gatsbyImageData}
          alt="René Zoller"
        />
      </ContactPerson>
    </Layout>
  );
};

export default HaiiloPartner;
